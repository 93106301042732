<script>
import Vue, { ref, computed, reactive } from 'vue'
import { useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { cardCode } from '/~/utils/cards'
import { formatDollar } from '/~/utils/format/money'
import { formatPoints } from '/~/utils/points'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import {
  usePurchaseCheckout,
  useQuickBuyCheckout,
} from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useCms } from '/~/composables/cms/use-cms'
import { useEwallet } from '/~/composables/ewallet'
import { useLocalization } from '/~/composables/localization'
import {
  getExpiryDate,
  usePaymentMethods,
  PaymentMethodType,
} from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'payments-methods-split-item',
  components: {
    BaseIcon,
    BaseLoader,
  },
  props: {
    method: {
      type: Object,
      default: () => ({}),
    },
    isSummary: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
    flowType: {
      type: String,
      default: FlowType.purchase,
      validator: (v) => !v || Object.values(FlowType).includes(v),
    },
  },
  setup(props) {
    const router = useRouter()
    const { initiatingAccountsIds, deletePaymentMethod } = usePaymentMethods()
    const { isBankAccountsNavEnabled, isCreditCardsNavEnabled } = useProvider()
    const {
      burnPointsRateOrder,
      burnPointsRatePaymentOrder,
      burnPointsRateBatchOrder,
      burnPointsRateStatementOrder,
      pointsBalance,
    } = usePoints()
    const { ewalletBalance } = useEwallet()
    const { translate } = useLocalization()
    const { ewalletLabels } = useCms()

    const paymentComposable = computed(() => {
      return router.currentRoute.meta.quickBuy
        ? useQuickBuyCheckout
        : usePurchaseCheckout
    })

    const {
      total,
      subTotalWithProgramFeesNoCoupons,
      readyToPay,
      payWithPoints,
      payWithEwallet,
      payWithBankAccount,
      payWithCard,
      selectedPoints,
      selectedEwallet,
      selectedCard,
      selectedBankAccount,
      selectedAmount,
      getTransactionFees,
      transactionFees,
      isEnoughPointsPaymentOrder,
      loadingFees,
      payment,
    } = paymentComposable.value()

    const loading = ref(false)

    const expiryDate = reactive(
      getExpiryDate(
        props.method,
        props.flowType === FlowType.payment && payment?.date
      )
    )

    const burnPointsRate = computed(() => {
      switch (props.flowType) {
        case FlowType.payment:
          return burnPointsRatePaymentOrder.value
        case FlowType.batch:
          return burnPointsRateBatchOrder.value
        case FlowType.statement:
          return burnPointsRateStatementOrder.value
      }

      return burnPointsRateOrder.value
    })

    async function onDelete() {
      if (loading.value) {
        return
      }

      loading.value = true
      try {
        await deletePaymentMethod(props.method.id)
      } catch (error) {
        Vue.notify({
          type: 'error',
          text: 'Something went wrong while deleting. Please try again.',
        })
      } finally {
        loading.value = false
      }
    }

    return {
      total,
      subTotalWithProgramFeesNoCoupons,
      readyToPay,
      payWithPoints,
      payWithEwallet,
      payWithBankAccount,
      payWithCard,
      selectedPoints,
      selectedEwallet,
      selectedCard,
      selectedBankAccount,
      selectedAmount,
      isBankAccountsNavEnabled,
      isCreditCardsNavEnabled,
      formatDollar,
      formatPoints,
      getTransactionFees,
      transactionFees,
      isEnoughPointsPaymentOrder,
      loadingFees,
      burnPointsRate,
      initiatingAccountsIds,
      payment,
      bottomSheet,
      onDelete,
      loading,
      ui,
      expiryDate,
      router,
      paymentComposable,
      PaymentMethodType,
      translate,
      ewalletLabels,
      pointsBalance,
      ewalletBalance,
    }
  },
  computed: {
    initializing() {
      return this.initiatingAccountsIds.includes(this.method.id) || this.loading
    },
    isPoints() {
      return this.method.type === this.PaymentMethodType.points
    },
    isCard() {
      return this.method.type === this.PaymentMethodType.creditCard
    },
    isBankAccount() {
      return this.method.type === this.PaymentMethodType.bankAccount
    },
    isEwallet() {
      return this.method.type === this.PaymentMethodType.eWallet
    },
    isScheduledPayment() {
      return this.flowType === FlowType.payment && this.payment?.date
    },
    status() {
      return (this.method?.status ?? '').toLowerCase()
    },
    state() {
      return (this.method?.state ?? '').toLowerCase()
    },
    isVerified() {
      return (
        this.status === 'verified' ||
        this.isPoints ||
        this.isEwallet ||
        (this.isCard && !this.isCreditCardsNavEnabled) ||
        (this.isBankAccount && !this.isBankAccountsNavEnabled)
      )
    },
    isNotAllowed() {
      return this.state === 'restricted'
    },
    isBlocked() {
      return this.status === 'blocked' || this.status === 'locked'
    },
    icon() {
      if (this.expiryDate.isExpired) {
        return 'v2/heroic/x'
      } else if (this.isPoints) {
        return 'symbion/token'
      } else if (this.isCard) {
        return `cards/${cardCode(this.method)}`
      } else if (this.isBankAccount) {
        return 'v2/custom/bank'
      } else if (this.isEwallet) {
        return 'plain/ewallet'
      }
      return null
    },
    iconSize() {
      if (this.isCard || this.isEwallet) {
        return 'xl'
      } else if (this.isBankAccount) {
        return 'md'
      }
      return '2xl'
    },
    firstRow() {
      const { name, brand, type, accountName } = this.method

      switch (type) {
        case this.PaymentMethodType.points:
          return 'Pay with points'
        case this.PaymentMethodType.creditCard:
          return `${name} ${brand}`
        case this.PaymentMethodType.bankAccount:
          return accountName
        case this.PaymentMethodType.eWallet:
          return `Pay with ${this.ewalletLabels.ewalletCash}`
        default:
          return ''
      }
    },
    secondRow() {
      const { number, accountNumber, bsb } = this.method
      const {
        isPoints,
        isEwallet,
        isCard,
        isBankAccount,
        isSelected,
        formatPoints,
        isSummary,
      } = this

      if (isPoints) {
        return isSelected || isSummary
          ? `Using ${formatPoints(this.selectedPoints?.usePoints)} PTS`
          : `Points balance ${formatPoints(this.pointsBalance)}`
      } else if (isEwallet) {
        return `${this.ewalletLabels.ewalletCashBalance} ${formatDollar(
          this.ewalletBalance
        )}`
      } else if (isCard) {
        return `**** **** **** ${number.slice(-4)}`
      } else if (isBankAccount) {
        return this.translate('bankAccount.details', {
          acc: ` *** *** *${accountNumber.slice(-2)}`,
          bsb,
        })
      }

      return null
    },
    methodFees() {
      if (this.loadingFees) {
        return 0
      } else {
        return this.method.fee ?? 0
      }
    },
    methodFeesText() {
      return `-${
        this.method.percentageFeeRate
          ? `${this.method.percentageFeeRate}%`
          : `$${this.methodFees}`
      } processing fee`
    },
    isSelected() {
      if (!this.method.id || this.isSummary) return false

      return (
        (this.isPoints && this.selectedPoints) ||
        (this.selectedCard?.id === this.method.id && this.payWithCard) ||
        (this.selectedBankAccount?.id === this.method.id &&
          this.payWithBankAccount) ||
        (this.selectedEwallet?.id === this.method.id && this.payWithEwallet)
      )
    },
    amountToPay() {
      if (this.isPoints) {
        return this.payWithPoints
      } else if (this.isCard) {
        return this.payWithCard
      } else if (this.isBankAccount) {
        return this.payWithBankAccount
      } else if (this.isEwallet) {
        return this.payWithEwallet
      } else {
        return 0
      }
    },
    isUnselectable() {
      return (
        this.isNotAllowed ||
        this.isDisabled ||
        !this.isVerified ||
        (this.isPoints && !this.pointsBalance) ||
        (this.isCard && this.expiryDate.isExpiryLessThanScheduledAt) ||
        this.expiryDate.isExpired
      )
    },
  },
  methods: {
    async onClick() {
      if (
        this.expiryDate.isExpired ||
        this.isBlocked ||
        this.initializing ||
        this.isNotAllowed
      ) {
        return
      }

      if (!this.isVerified) {
        await this.$router.push({
          hash: '#profile-payment-methods',
          query: this.$route.query,
        })
        bottomSheet.show('method-verify', {
          to: 'menu-modal',
          props: {
            method: this.method,
          },
        })
        return
      }

      if (
        this.isSummary ||
        this.isUnselectable ||
        (this.isSelected && !this.isPoints && !this.isEwallet)
      ) {
        return
      }

      if (this.isCard && !this.selectedBankAccount) {
        this.selectedCard = {
          ...this.method,
          calculatedAmount:
            this.subTotalWithProgramFeesNoCoupons -
            (this.selectedPoints?.calculatedAmount ?? 0) -
            (this.selectedEwallet?.calculatedAmount ?? 0),
        }
        this.getTransactionFees()
        this.$emit('confirm')
        return
      } else if (this.isBankAccount && !this.selectedCard) {
        this.selectedBankAccount = {
          ...this.method,
          calculatedAmount:
            this.subTotalWithProgramFeesNoCoupons -
            (this.selectedPoints?.calculatedAmount ?? 0) -
            (this.selectedEwallet?.calculatedAmount ?? 0),
        }
        this.getTransactionFees()
        this.$emit('confirm')
        return
      }

      const splitModalProps = {
        currentItem: this.method,
        burnPointsRate: this.burnPointsRate,
        selectedPoints: this.selectedPoints,
        selectedEwallet: this.selectedEwallet,
        selectedCard: this.selectedCard,
        selectedBankAccount: this.selectedBankAccount,
        selectedAmount: this.selectedAmount,
        total: this.subTotalWithProgramFeesNoCoupons,
        onConfirm: this.onConfirm,
        onCancel: this.onCancel,
      }

      if (ui.mobile) {
        this.bottomSheet.show('payments-split', {
          to: this.to,
          props: splitModalProps,
        })
      } else {
        modal.show('payments-split-modal', {
          props: splitModalProps,
        })
      }
    },
    onConfirm({
      pointsToUse,
      pointsAmount,
      ewalletAmount,
      cardAmount,
      bankAccountAmount,
    }) {
      const { type, id } = this.method

      if (pointsToUse > 0) {
        this.selectedPoints = {
          ...this.selectedPoints,
          ...(type === this.PaymentMethodType.points &&
            id !== this.selectedPoints?.id &&
            this.method),
          usePoints: pointsToUse,
          calculatedAmount: pointsAmount,
        }
      } else {
        this.selectedPoints = null
      }

      if (ewalletAmount > 0) {
        this.selectedEwallet = {
          ...this.selectedEwallet,
          ...(type === this.PaymentMethodType.eWallet &&
            id !== this.selectedEwallet?.id &&
            this.method),
          calculatedAmount: ewalletAmount,
        }
      } else {
        this.selectedEwallet = null
      }

      if (cardAmount > 0) {
        this.selectedCard = {
          ...this.selectedCard,
          ...(type === this.PaymentMethodType.creditCard &&
            id !== this.selectedCard?.id &&
            this.method),
          calculatedAmount: cardAmount,
        }
      } else {
        this.selectedCard = null
      }

      if (bankAccountAmount > 0) {
        this.selectedBankAccount = {
          ...this.selectedBankAccount,
          ...(type === this.PaymentMethodType.bankAccount &&
            id !== this.selectedBankAccount?.id &&
            this.method),
          calculatedAmount: bankAccountAmount,
        }
      } else {
        this.selectedBankAccount = null
      }

      this.$emit('confirm')

      this.getTransactionFees()
    },
    onCancel() {
      this.$emit('cancel')
    },
    onUnselect() {
      this.$emit('unselect')
    },
  },
}
</script>

<template>
  <div
    :class="[
      'relative rounded-lg bg-white p-[15px]',
      isUnselectable ? 'text-neutral-400 opacity-75' : 'cursor-pointer',
      !isVerified && 'text-neutral-400',
      isBlocked && 'pointer-events-none cursor-default',
    ]"
    @click="onClick"
  >
    <div class="flex items-center">
      <div
        class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full"
        :class="{
          'bg-primary': !isUnselectable && isBankAccount,
          'text-black': !isUnselectable && isEwallet,
          'text-white': !isUnselectable && !isEwallet,
          'bg-red-700 text-white': expiryDate.isExpired,
        }"
      >
        <base-icon
          v-if="icon"
          :svg="icon"
          :size="iconSize"
          :class="{
            'text-primary': isPoints,
          }"
          :style="(isUnselectable || isDisabled) && 'filter:grayscale(1)'"
        />
      </div>
      <div class="mx-[15px] flex flex-col">
        <span
          class="font-bold"
          :class="{
            'text-eonx-neutral-600': !isVerified,
          }"
        >
          {{ firstRow }}
        </span>
        <span
          v-if="secondRow"
          class="text-sm"
          :class="{
            'text-eonx-neutral-600': isVerified,
            'text-eonx-neutral-600': !isVerified,
          }"
        >
          {{ secondRow }}
          <span
            v-if="expiryDate.isExpired"
            class="mx-[5px] cursor-pointer border-l px-[5px] text-red-700 hover:opacity-60"
            @click="onDelete"
          >
            Remove
          </span>
        </span>
      </div>
      <base-loader v-if="initializing" class="ml-auto shrink-0" size="xs" />
      <div
        v-else-if="isBlocked"
        class="ml-auto shrink-0 text-sm text-eonx-neutral-600"
      >
        ACCOUNT BLOCKED
      </div>
      <div
        v-else-if="isNotAllowed"
        class="absolute right-0 top-0 mr-2 mt-2 whitespace-nowrap rounded-sm border border-orange-700 bg-orange-50 px-2 text-xs font-bold uppercase leading-5 text-orange-700"
      >
        NOT ACCEPTED
      </div>
      <div v-else-if="isSelected" class="ml-auto shrink-0 text-right">
        <div class="font-bold">- {{ formatDollar(amountToPay) }}</div>
        <div v-if="isPoints" class="text-sm text-eonx-neutral-600">Value</div>
        <div v-if="methodFees" class="text-sm text-eonx-neutral-600">
          {{ methodFeesText }}
        </div>
      </div>

      <button
        v-if="isSelected"
        class="ml-6 flex h-6 w-6 items-center justify-center rounded-full border bg-eonx-neutral-50 text-eonx-neutral-800 hover:opacity-75"
        @click.stop.prevent="onUnselect"
      >
        <base-icon svg="close" :size="19" />
      </button>
      <div
        v-if="expiryDate.isExpired && !initializing"
        class="absolute right-0 top-0 mr-2.5 mt-2.5 whitespace-nowrap rounded border-red-700 bg-red-50 px-2.5 text-xs font-bold leading-5 text-red-700"
        :style="{ 'box-shadow': '0px 0px 0px 1px #EF4444' }"
      >
        Expired
      </div>
      <div
        v-else-if="!isVerified && !initializing"
        class="absolute right-0 top-0 -mt-[5px] whitespace-nowrap rounded-l-md bg-orange-400 px-6 text-xs font-bold uppercase leading-5 text-white sm:mt-[15px]"
      >
        Verify Account
      </div>
    </div>

    <div
      v-if="expiryDate.isExpiryLessThanScheduledAt"
      class="mt-[5px] text-sm text-eonx-neutral-600"
    >
      Card will expire before the scheduled date
    </div>
  </div>
</template>
